import { Fragment, useEffect, useMemo, useState } from "react";
import { FilterHeader, NavBar, Table } from "../../components";

//Icons
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useListOrders } from "../../@core/hooks/";
import { Order, User } from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";

function Orders() {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const { merchant } = useCurrentMerchantSelector();

  const [page, setPage] = useState(0);

  const {
    data: orders,
    isLoading: isOrdersLoading,
    refetch: RefetchOrderList,
    isRefetching,
  } = useListOrders({
    storeId: merchant!!._id,
    limit: 10,
    page,
  });

  useEffect(() => {
    RefetchOrderList();
  }, [page]);

  const handlePageChange = (selectedItem: any) => {
    setPage(selectedItem.selected);
  };

  const columns = useMemo(
    () => [
      {
        title: t("order.orderNumber"),
        selector: (row: Order) => row.orderRefId,
      },
      {
        title: t("order.customer"),
        selector: (row: Order) => (row.buyer as User)?.name,
      },
      {
        title: t("order.total"),
        selector: (row: Order) => row.totalPrice + " " + t("currency.kwd"),
      },
      {
        title: t("order.date"),
        selector: (row: Order) =>
          new Date(row.createdAt).toLocaleDateString().toLocaleUpperCase(),
      },
      {
        title: t("order.status"),
        selector: (row: Order) => (
          <span
            className={`${
              row.status === "Pending"
                ? "pendingStatus"
                : row.status === "Delivered"
                ? "approvedStatus"
                : row.status === "Accepted" ||
                  row.status === "Packing" ||
                  row.status === "Placed" ||
                  row.status === "Shipping"
                ? "acceptedStatus"
                : row.status === "Cancelled"
                ? "cancelledStatus"
                : ""
            }`}
          >
            {row.status}
          </span>
        ),
      },
      {
        title: t("order.action"),
        selector: (row: Order) => (
          <>
            <AiOutlineEye
              size={20}
              onClick={() => navigation(`/orders/${row._id}`)}
            />
          </>
        ),
      },
    ],
    [t]
  );

  if (isOrdersLoading || isRefetching)
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.orders")} />
        <FilterHeader />

        <div className="tableContainer" id="tableContainer">
          <Table columns={columns} data={[]}>
            <LoadingSpinner />
          </Table>
        </div>
      </Fragment>
    );

  return (
    <Fragment>
      <NavBar title={t("menuNavigation.orders")} />
      <FilterHeader />
      <div className="tableContainer" id="tableContainer">
        <Table
          columns={columns}
          data={orders!!.data}
          handlePageChange={handlePageChange}
          pageCount={orders!!.numberOfPages}
          initialPage={page}
        />
      </div>
    </Fragment>
  );
}

export default Orders;
