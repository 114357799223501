import instance from "../../../utils/axios.util";
import { ICreateEmployeeDto } from "../../models";

interface CreateEmployeeProps {
  storeId: string;
  body: ICreateEmployeeDto;
}

export async function CreateEmployee({ storeId, body }: CreateEmployeeProps) {
  try {
    const res = await instance.post(`${storeId}/store-employee`, { ...body });
    return res.data;
  } catch (error) {
    throw error;
  }
}
