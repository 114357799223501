import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
	Controller,
	Control,
	FieldError,
	RegisterOptions,
} from 'react-hook-form';
import { FormHelperText } from '@mui/material';

interface Props extends React.HTMLProps<HTMLDivElement> {
	name: string;
	label: string;
	control?: Control<any>;
	error?: any;
	placeholder?: string;
	rules?:
		| Omit<
				RegisterOptions<any, string>,
				| 'valueAsNumber'
				| 'valueAsDate'
				| 'setValueAs'
				| 'disabled'
		  >
		| undefined;
}

export const TimePickerInput: React.FC<Props> = ({
	name,
	label,
	control,
	error,
	placeholder,
	rules = {}
}) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<LocalizationProvider
					dateAdapter={AdapterDayjs}>
					<TimePicker
						{...field}
						label={label}
						slotProps={{
							textField: {
								error: !!error,
							},
						}}
					/>
					<FormHelperText error={!!error}>
						{error?.message}
					</FormHelperText>
				</LocalizationProvider>
			)}
		/>
	);
};
