import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const addTimeToDate = (date: Dayjs, time: Dayjs) => {
	const value = dayjs(date)
		.set('hour', time.hour())
		.set('minute', time.minute())
		.set('second', time.second())
		.set('millisecond', time.millisecond());

	return value.utc();
};
