import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { forwardRef } from "react";

const phoneInputStyle = makeStyles(() => ({
  input: {
    backgroundColor: "#fff",
  },
}));

const phoneInput = (props: any, ref: any) => {
  const classes = phoneInputStyle();

  return (
    <TextField
      {...props}
      InputProps={{
        className: classes.input,
      }}
      inputRef={ref}
      fullWidth
      size="small"
      label="Phone Number"
      variant="outlined"
      name="phone"
    />
  );
};
export default forwardRef(phoneInput);
