import { ListEmployeeResponse } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface Props {
  storeId: string;
  page: number;
  limit: number;
}

export const useListEmployees = ({ storeId, page, limit }: Props) =>
  useCustomQuery<ListEmployeeResponse>({
    url: `${storeId}/store-employee`,
    page,
    limit,
  });
