import instance from "../../../utils/axios.util";
import { updateStoreDto } from "../../models";

interface IupdateStoreDto {
  id: string;
  body: Partial<updateStoreDto>;
}

export async function UpdateStore({ id, ...body }: IupdateStoreDto) {
  try {
    console.log("____-----____", id, body);
    const res = await instance.patch(`store/${id}`, { ...body });
    return res.data;
  } catch (error) {
    throw error;
  }
}
