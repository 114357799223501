import { ProductResponse } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface Props {
  productId: string;
  storeId: string;
  options?: any;
}

export const useProduct = ({ storeId, productId, options }: Props) =>
  useCustomQuery<ProductResponse>({
    url: `${storeId}/product/${productId}`,
    options,
  });
