import React from "react";
import SelectBox from "../SelectBox";

//styles
import "./style.scss";

//Icons
import { BiSearch } from "react-icons/bi";
import { HiPlus } from "react-icons/hi";

type FilterHeaderProps = {
  hasSearch?: boolean;
  searchPlaceHolder?: string;
  searchValue?: string;
  searchName?: string;
  searchOnChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;

  hasStatus?: boolean;
  selectorPlaceHolder?: string;
  selectorValue?: string;
  selectorName?: string;
  selectorOnChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  selectorData?: () => any[];

  hasAdd?: boolean;
  iconTitle?: string;
  onAdd?: () => void;
};

function FilterHeader({
  hasSearch = true,
  searchPlaceHolder = "Search for product, vendor, category",
  searchValue = "",
  searchName = "",
  searchOnChange = () => {},

  hasStatus = true,
  selectorPlaceHolder = "Filter by status",
  selectorValue = "",
  selectorName = "",
  selectorOnChange = () => {},
  selectorData = () => [],

  hasAdd = true,
  iconTitle = "",
  onAdd = () => {},
}: FilterHeaderProps) {
  return (
    <div className="filterHeaderContainer">
      {hasSearch && (
        <div>
          <BiSearch />
          <input
            type="text"
            placeholder={searchPlaceHolder}
            value={searchValue}
            name={searchName}
            onChange={searchOnChange}
          />
        </div>
      )}
      {hasStatus && (
        <SelectBox
          getData={() => selectorData}
          onChange={selectorOnChange}
          value={selectorValue}
          name={selectorName}
          placeHolder={selectorPlaceHolder}
        />
      )}
      {hasAdd && (
        <button className="addContainer" onClick={onAdd} aria-label={iconTitle}>
          {iconTitle} <HiPlus size={20} />
        </button>
      )}
    </div>
  );
}

export default FilterHeader;
