import { ListProductsResponse } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface Props {
  storeId: string;
  page?: number;
  limit?: number;
  paginated?: boolean;
}

export const useListProducts = ({
	storeId,
	page = 1,
	limit = 10,
	paginated = true,
}: Props) =>
	useCustomQuery<ListProductsResponse>({
		url: `${storeId}/product`,
		page,
		limit,
    paginated,
	});
