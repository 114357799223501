import { useQuery } from "react-query";
import { fetcher } from "../../api/fetcher.api";

interface Props {
  url: string;
  dependedKey?: string;
  page?: number;
  limit?: number;
  dependedParams?: string[] | number[] | boolean[];
  options?: any;
  paginated?: boolean;
}

export const useCustomQuery = <T>({
  url,
  dependedKey,
  page = 1,
  limit,
  dependedParams,
  options,
  paginated = true,
}: Props) => {
  const params = dependedParams?.length ? { ...dependedParams } : [];
  const skip = limit && page ? limit * page : 0;

  return useQuery<T, Error>(
    [dependedKey || url, params],
    (): Promise<T> =>
      fetcher(`/${url}`, {
        limit,
        skip,
        paginated,
      }),
    options
  );
};
