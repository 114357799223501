import { useQuery } from 'react-query'
import { getSubcategories } from '../../../api/categories/categories.api';

export const useSubcategories = (mainCategoryId: string) => {
	return useQuery(
		['subcategories', mainCategoryId],
		() => getSubcategories(mainCategoryId),
		{
			enabled: !!mainCategoryId,
		}
	);
}
