import { GetDiscountDto } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface useListDiscountProps {
  storeId: string;
  limit?: number;
  page?: number;
}

export const useListDiscount = ({
	storeId,
	limit,
	page,
}: useListDiscountProps) =>
	useCustomQuery<GetDiscountDto>({
		url: `${storeId}/discount`,
		dependedKey: `discount/store/${storeId}`,
	});
