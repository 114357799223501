import React from "react";

//Styles
import "./style.scss";

type InputBoxProps = {
  name?: string;
  type: string;
  placeholder: string;
  value: any;
  onChange: (value: any) => void;
  label?: string;
};

function InputBox({
  name,
  type,
  placeholder,
  value,
  onChange,
  label,
}: InputBoxProps) {
  return (
    <div className="inputBoxContainer">
      {label && <label>{label}</label>}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default InputBox;
