import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface User {
  user: {
    _id: string;
    name: string;
    email: string;
  };
  accessToken: string;
}

interface AuthState {
  user: User | null;
}

const initialState: AuthState = { user: null };

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    loginSuccess(state, { payload }) {
      state.user = payload;
      return state;
    },
    logOut(state) {
      state.user = null;
    },
    updateUser(state, { payload }) {
      if (payload) {
        state.user = payload;
      }
    },
  },
});

export const useCurrentUserSelector = () =>
  useSelector((state: RootState) => state.auth);
export const useCurrentTokenSelector = () =>
  useSelector((state: RootState) => state.auth.user);

export default authSlice.reducer;
export const { loginSuccess, logOut, updateUser } = authSlice.actions;
