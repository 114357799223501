import { Box, Button, FormHelperText, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FileUploader } from "react-drag-drop-files";
import { Control, Controller, useFieldArray, useForm } from "react-hook-form";
import { CgFileDocument } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useProduct, useUpdateProduct } from "../../@core/hooks";
import {
  Color,
  Size,
  StockProduct,
  StockProductFormData,
} from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { theme } from "../../assets/styles/mui-theme";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import NavBar from "../NavBar";
import "./style.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const EditStockProductForm: React.FC<Props> = () => {
  const navigte = useNavigate();
  const { merchant } = useCurrentMerchantSelector();
  const { productId } = useParams();

  const { data, isLoading: isProductLoading } = useProduct({
    productId: productId as string,
    storeId: merchant!!._id,
  });

  const product = data?.data as StockProduct;

  const { mutate: updateProduct } = useUpdateProduct();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<StockProductFormData>({
    defaultValues: {
      nameEn: product?.translation?.name,
      nameAr: product?.name,
      descriptionEn: product?.translation?.description,
      descriptionAr: product?.description,

      sku: product?.sku,
      mainImage: product?.mainImage,
      price: product?.price,
      shippingPrice: product?.shippingPrice,
      stock: product?.stock || 0,
      variants:
        product?.variants?.map((variant) => ({
          _id: variant._id,
          color: (variant.color as Color).id ?? "",
          size: (variant.size as Size).id ?? "",
          price: variant.price ?? 0,
          stock: variant.stock ?? 0,
          images: variant.images ?? [],
        })) || [],
      currency: product?.currency,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "variants" as never,
  });

  const watchImage = watch("mainImage");

  const handleImageChange = (image: File) => {
    setValue("mainImage", image);
  };

  const handleVariantImageChange = (images: File[], index: number) => {
    setValue(`variants.${index}.images`, images);
  };

  const onSubmit = (values: StockProductFormData) => {
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      if (key === "variants") {
        value.forEach((variant: any, index: number) => {
          if (variant.images) {
            Array.from(variant.images).forEach((image: any, key) => {
              formData.append(`variants[${index}].images[${key}]`, image);
            });
          }
          if (variant.images[0] instanceof File) delete variant.images;
        });
        formData.append(key, JSON.stringify(value));

        return;
      }
      if (key === "tags") {
        const sendValue = value.map(({ _id, ...el }: any) => el);
        formData.append(key, JSON.stringify(sendValue));
        return;
      }
      if (
        key === "subCategory" &&
        (value === "" || value === null || value === undefined)
      ) {
        return;
      }
      formData.append(key, value);
    });

    updateProduct(
      {
        storeId: merchant?._id!,
        productId,
        body: formData,
      },
      {
        onSuccess: () => {
          toast.success("Product updated successfully");
          navigte(`/products/${productId}`);
        },
        onError: () => {
          toast.error("Failed to update product, please try again");
        },
      }
    );
  };

  if (isProductLoading) {
    return (
      <div className="content">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="stock">
      <NavBar title={"Edit Product"} status={product.status} hasBack={true} />

      <div className="editProductAction">
        <div className="routes">
          <span onClick={() => navigte("/products")}>Products</span>
          <IoIosArrowForward fontWeight={900} size={20} />
          <span>
            {" "}
            {`Editing `}{" "}
            <span
              style={{ color: "sandybrown" }}
              onClick={() => navigte(`/products/${productId}`)}
            >
              {product.translation.name ?? ""}
            </span>
          </span>
        </div>
      </div>

      <div className="content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Box>
              <BasicDetailsForm control={control} errors={errors} />
              <br />
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
              >
                <Grid item className="header" md={12} xs={12}>
                  <CgFileDocument />
                  Product Image
                </Grid>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    paddingRight={3}
                    paddingLeft={3}
                    marginTop={2}
                    marginBottom={2}
                  >
                    {/* Image */}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FileUploader
                          accept="image/*"
                          handleChange={handleImageChange}
                          types={["JPEG", "PNG"]}
                        >
                          <div
                            style={{
                              padding: "1.2rem",
                              width: "100%",
                              height: "420px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px dashed #344767",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                          >
                            {watchImage instanceof File ? (
                              <p>Image name: {watchImage?.name}</p>
                            ) : (
                              <img
                                src={watchImage}
                                alt="product"
                                style={{
                                  borderRadius: "8px",
                                  height: "auto",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                            <div>
                              <button
                                style={{
                                  all: "unset",
                                  color: "#1f5199",
                                  borderRadius: "8px",
                                  backgroundColor: "#e5effd",
                                  padding: "0.6rem 0.6rem",
                                  marginBottom: "0.5rem",
                                }}
                              >
                                Change Image
                              </button>
                              <p>Accepts .png and .jpg</p>
                            </div>
                          </div>
                        </FileUploader>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <br />
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
              >
                <Grid item className="header" md={12} xs={12}>
                  <CgFileDocument />
                  Price & Shipping
                </Grid>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    paddingRight={3}
                    paddingLeft={3}
                    marginTop={2}
                    marginBottom={2}
                  >
                    <Grid item xs={12} md={4}>
                      {/* Product Price */}
                      <FormControl fullWidth>
                        <Controller
                          name="price"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Price is required",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              {...field}
                              label="Price"
                              inputProps={{
                                inputMode: "numeric",
                              }}
                              type="number"
                              error={!!errors.price}
                            />
                          )}
                        />
                        <FormHelperText error={!!errors.price}>
                          {errors.price?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      {/* Shipping Price */}
                      <FormControl fullWidth>
                        <Controller
                          name="shippingPrice"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Shipping Price is required",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              {...field}
                              label="Shipping Price"
                              inputProps={{
                                inputMode: "numeric",
                              }}
                              type="number"
                              error={!!errors.shippingPrice}
                            />
                          )}
                        />
                        <FormHelperText error={!!errors.shippingPrice}>
                          {errors.shippingPrice?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      {/* Stock */}
                      <FormControl fullWidth>
                        <Controller
                          name="stock"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              {...field}
                              label="Stock"
                              inputProps={{
                                inputMode: "numeric",
                              }}
                              type="number"
                              error={!!errors.stock}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <br />

              <Grid
                container
                rowSpacing={3}
                columnSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
              >
                <Grid item className="header" md={6} xs={6}>
                  <CgFileDocument />
                  Variants
                </Grid>
                <Grid
                  item
                  className="header"
                  md={6}
                  xs={6}
                  style={{
                    justifyContent: "flex-end",
                  }}
                />

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    paddingRight={3}
                    paddingLeft={3}
                    marginTop={2}
                    marginBottom={2}
                  >
                    {fields.map(({ id }, index) => {
                      return (
                        <>
                          <Grid item xs={12} md={6} key={id}>
                            {/* Variant Price */}
                            <FormControl fullWidth>
                              <Controller
                                // @ts-ignore
                                name={`variants[${index}].price`}
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Price is required",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    {...field}
                                    label={`Price - ${index + 1}`}
                                    inputProps={{
                                      inputMode: "numeric",
                                    }}
                                    type="number"
                                    error={!!errors.price}
                                  />
                                )}
                              />
                              <FormHelperText error={!!errors.price}>
                                {errors.price?.message}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} key={id}>
                            {/* Variant Stock */}
                            <FormControl fullWidth>
                              <Controller
                                // @ts-ignore
                                name={`variants[${index}].stock`}
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Variant Stock is required",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    {...field}
                                    label={`Variant Stock - ${index + 1}`}
                                    inputProps={{
                                      inputMode: "numeric",
                                    }}
                                    type="number"
                                    error={!!errors.variants}
                                  />
                                )}
                              />
                              <FormHelperText error={!!errors.variants}>
                                {errors.variants?.message}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          {/* Image */}
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <FileUploader
                                accept="image/*"
                                handleChange={(files: any) => {
                                  handleVariantImageChange(files, index);
                                }}
                                types={["JPEG", "PNG"]}
                                multiple
                              >
                                <div className="file-uploader-body">
                                  <button className="btn">Change Images</button>
                                  <p>Accepts .png and .jpg</p>
                                </div>
                              </FileUploader>
                            </FormControl>
                          </Grid>
                          <br />
                        </>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>

              {/* Cancel and Sumbit */}
              <Grid item xs={12} padding={1} margin={1}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                >
                  <Button
                    type="button"
                    size="large"
                    variant="outlined"
                    color="error"
                    onClick={() => navigte(`/products/${productId}`)}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="basePrimary"
                  >
                    Update Product
                  </Button>
                </Stack>
              </Grid>
            </Box>
          </div>
        </form>
      </div>
    </div>
  );
};

type BasicDetailsFormProps = {
  control: Control<StockProductFormData, any>;
  errors: any;
};

const BasicDetailsForm = ({ control, errors }: BasicDetailsFormProps) => {
  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <Grid item className="header" md={12} xs={12}>
        <CgFileDocument />
        Basic Details
      </Grid>

      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          paddingRight={3}
          paddingLeft={3}
          marginTop={2}
          marginBottom={2}
        >
          <Grid item xs={12} md={4}>
            {/* Name Arabic */}
            <FormControl fullWidth>
              <Controller
                name="nameAr"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Arabic name is required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    label="Product name (Arabic)"
                    inputProps={{
                      inputMode: "text",
                    }}
                    type="string"
                    placeholder=""
                    error={!!errors.nameAr}
                  />
                )}
              />
              <FormHelperText error={!!errors.nameAr}>
                {errors.nameAr?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* Name English */}
            <FormControl fullWidth>
              <Controller
                name="nameEn"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "English name is required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    label="Product Name (English)"
                    inputProps={{
                      inputMode: "text",
                    }}
                    type="string"
                    placeholder=""
                    error={!!errors.nameEn}
                  />
                )}
              />
              <FormHelperText error={!!errors.nameEn}>
                {errors.nameEn?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* SKU */}
            <FormControl fullWidth>
              <Controller
                name="sku"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "SKU is required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    label="SKU"
                    inputProps={{
                      inputMode: "text",
                    }}
                    type="string"
                    placeholder=""
                    error={!!errors.sku}
                  />
                )}
              />
              <FormHelperText error={!!errors.sku}>
                {errors.sku?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Description Arabic */}
            <FormControl fullWidth>
              <Controller
                name="descriptionAr"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Arabic description is required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    label="Product description (Arabic)"
                    inputProps={{
                      inputMode: "text",
                    }}
                    multiline
                    maxRows={4}
                    type="string"
                    placeholder=""
                    error={!!errors.descriptionAr}
                  />
                )}
              />
              <FormHelperText error={!!errors.descriptionAr}>
                {errors.descriptionAr?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Description English */}
            <FormControl fullWidth>
              <Controller
                name="descriptionEn"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "English description is required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    label="Product description (English)"
                    inputProps={{
                      inputMode: "text",
                    }}
                    multiline
                    maxRows={4}
                    type="string"
                    placeholder=""
                    error={!!errors.descriptionEn}
                  />
                )}
              />
              <FormHelperText error={!!errors.descriptionEn}>
                {errors.descriptionEn?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
