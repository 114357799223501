import React from "react";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material";

export const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();

  const handleChangeLanguage = (event: SelectChangeEvent) =>
    i18n.changeLanguage(event.target.value);

  return (
    <Select
      size="small"
      autoWidth
      aria-hidden
      label={t("lang.title")}
      onChange={handleChangeLanguage}
      defaultValue={i18n.language}
    >
      <MenuItem value="en">{t("lang.en")}</MenuItem>
      <MenuItem value="ar">{t("lang.ar")}</MenuItem>
    </Select>
  );
};
