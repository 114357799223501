import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useListEmployees } from "../../@core/hooks/query/employee";
import { Employee, IEmployee } from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { FilterHeader, FloatingForm, NavBar, Table } from "../../components";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { AddEmployeeForm } from "./components";

//Icons
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { ViewEmployee } from "./components/view-employee.component";

function Employees() {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const { merchant } = useCurrentMerchantSelector();

  const [formVisible, setFormVisible] = useState(false);
  const [viewEmployeeDetails, setViewEmployeeDetails] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();

  const { data: employees, isLoading: isEmployeesLoading } = useListEmployees({
    storeId: merchant!!._id,
    limit: 10,
    page: 0,
  });

  const columns = useMemo(
    () => [
      {
        title: t("employee.name"),
        selector: (row: IEmployee) => row.name,
      },
      {
        title: t("employee.email"),
        selector: (row: IEmployee) => row.email,
      },
      {
        title: t("employee.phone"),
        selector: (row: IEmployee) => row.phoneNumber,
      },
      {
        title: t("employee.job"),
        selector: (row: IEmployee) => row.job,
      },
      {
        title: t("employee.action"),
        selector: (row: IEmployee) => (
          <div className="flexActions">
            <AiOutlineEye
              size={20}
              onClick={() => {
                setSelectedEmployee(row as any);
                setViewEmployeeDetails(true);
              }}
            />
            <FiEdit size={20} onClick={() => setFormVisible(true)} />
          </div>
        ),
      },
    ],
    [t]
  );

  if (isEmployeesLoading)
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.employees")} />
        <FilterHeader />
        <div className="tableContainer" id="tableContainer">
          <Table columns={columns} data={[]}>
            <LoadingSpinner />
          </Table>
        </div>
      </Fragment>
    );

  return (
    <div>
      <NavBar title={t("menuNavigation.employees")} />
      <FilterHeader
        hasAdd
        onAdd={() => {
          setFormVisible(true);
        }}
      />
      <div className="tableContainer" id="tableContainer">
        <Table columns={columns} data={employees!!.data} />
      </div>
      <FloatingForm
        visible={formVisible}
        setVisible={() => setFormVisible(false)}
        title="Add Employee"
        body={<AddEmployeeForm closeModal={() => setFormVisible(false)} />}
        btnName="Save"
      />

      <FloatingForm
        visible={viewEmployeeDetails}
        setVisible={() => setViewEmployeeDetails(false)}
        title="Employee Details"
        body={
          <ViewEmployee
            selectedEmployee={selectedEmployee}
            closeModal={() => {
              setSelectedEmployee(undefined);
              setViewEmployeeDetails(false);
            }}
          />
        }
      />
    </div>
  );
}

export default Employees;
