import React from "react";
import { Link } from "react-router-dom";

//styles
import "./style.scss";

function RestConfirmation() {
  return (
    <div className="resetConfirmationContainer">
      <div className="container">
        <div className="svgContainer">
          <img src="/confirmation.png" alt="" />
        </div>
        <div className="content">
          <h3>Password Reset Successfully</h3>
          <span>
            Click <Link to="/login"> here</Link> to log in to your Base account
          </span>
        </div>
      </div>
    </div>
  );
}

export default RestConfirmation;
