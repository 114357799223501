import { Box, Button, FormHelperText, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { FileUploader } from "react-drag-drop-files";
import { Control, Controller, useFieldArray, useForm } from "react-hook-form";
import { CgFileDocument } from "react-icons/cg";
import { HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useListColor,
  useListSize,
  useListTag,
  useSubcategories,
} from "../../@core/hooks";
import { useCreateProduct } from "../../@core/hooks/mutation/product/use-create-product.hook";
import { StockProductFormData } from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { theme } from "../../assets/styles/mui-theme";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import NavBar from "../NavBar";
import "./style.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const AddStockProductForm: React.FC<Props> = () => {
  const navigte = useNavigate();
  const { merchant } = useCurrentMerchantSelector();
  const { data: tags, isLoading: isTagsLoading } = useListTag({
    categoryId: merchant?.category._id!,
  });
  const { data: colors, isLoading: isColorsLoading } = useListColor();
  const { data: sizes, isLoading: isSizesLoading } = useListSize();

  const { data: subCategories, isLoading: isSubcategoryLoading } =
    useSubcategories(merchant?.category._id!);

  const { mutate: createStockProduct } = useCreateProduct();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<StockProductFormData>({
    defaultValues: {
      nameEn: "",
      nameAr: "",
      descriptionEn: "",
      descriptionAr: "",
      store: merchant?._id,
      sku: "",
      mainImage: undefined,
      price: undefined,
      shippingPrice: undefined,
      stock: 0,
      category: merchant?.category._id,
      subCategory: "",
      variants: [],
      tags: [],
      currency: "KWD",
      isMenuProduct: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variants" as never,
  });

  const watchImage = watch("mainImage");

  const handleImageChange = (image: File) => {
    setValue("mainImage", image);
  };

  const handleVariantImageChange = (images: File[], index: number) => {
    setValue(`variants.${index}.images`, images);
  };

  const onSubmit = (values: StockProductFormData) => {
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      if (key === "variants") {
        value.forEach((variant: any, index: number) => {
          if (variant.images) {
            Array.from(variant.images).forEach((image: any, key) => {
              formData.append(`variants[${index}].images[${key}]`, image);
            });
          }
          delete variant.images;
        });
        formData.append(key, JSON.stringify(value));

        return;
      }

      if (key === "tags") {
        formData.append(key, JSON.stringify(value));
        return;
      }
      if (
        key === "subCategory" &&
        (value === "" || value === null || value === undefined)
      ) {
        return;
      }
      formData.append(key, value);
    });

    createStockProduct(
      { storeId: merchant?._id!, body: formData },
      {
        onSuccess: () => {
          toast.success("Product added successfully");
          navigte("/products");
        },
        onError: () => {
          toast.error("Failed to add product, please try again");
        },
      }
    );
  };

  if (
    isSubcategoryLoading ||
    isTagsLoading ||
    isColorsLoading ||
    isSizesLoading
  ) {
    return (
      <div
        style={{
          width: "calc(100% - 300px)",
          margin: "160px 15px 30px auto",
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="stock">
        <NavBar title={"Create Product"} status={""} hasBack={true} />

        <div className="content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <BasicDetailsForm
                control={control}
                categoryName={merchant?.category.name as string}
                subcategories={subCategories?.data}
                tags={tags?.data}
                errors={errors}
              />
            </Box>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              <Grid item className="header" md={12} xs={12}>
                <CgFileDocument />
                Product Image
              </Grid>

              <Grid
                container
                spacing={2}
                paddingRight={3}
                paddingLeft={3}
                marginTop={2}
                marginBottom={2}
              >
                {/* Image */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FileUploader
                      accept="image/*"
                      handleChange={handleImageChange}
                      types={["JPEG", "PNG"]}
                    >
                      <div className="file-uploader-body">
                        <button className="btn">Add Image</button>
                        {watchImage instanceof File ? (
                          <p>Image name: {watchImage?.name}</p>
                        ) : (
                          <img src={watchImage} alt="product" />
                        )}
                        <p>Accepts .png and .jpg</p>
                      </div>
                    </FileUploader>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              <Grid item className="header" md={12} xs={12}>
                <CgFileDocument />
                Price & Shipping
              </Grid>

              <Grid
                container
                spacing={2}
                paddingRight={3}
                paddingLeft={3}
                marginTop={2}
                marginBottom={2}
              >
                <Grid item xs={12} md={4}>
                  {/* Product Price */}
                  <FormControl fullWidth>
                    <Controller
                      name="price"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Price is required",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          {...field}
                          label="Price"
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          type="number"
                          error={!!errors.price}
                        />
                      )}
                    />
                    <FormHelperText error={!!errors.price}>
                      {errors.price?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  {/* Shipping Price */}
                  <FormControl fullWidth>
                    <Controller
                      name="shippingPrice"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Shipping Price is required",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          {...field}
                          label="Shipping Price"
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          type="number"
                          error={!!errors.shippingPrice}
                        />
                      )}
                    />
                    <FormHelperText error={!!errors.shippingPrice}>
                      {errors.shippingPrice?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  {/* Stock */}
                  <FormControl fullWidth>
                    <Controller
                      name="stock"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          {...field}
                          label="Stock"
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          type="number"
                          error={!!errors.stock}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              <Grid item className="header" md={6} xs={6}>
                <CgFileDocument />
                Variants
              </Grid>
              <Grid
                item
                className="header"
                md={6}
                xs={6}
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <HiPlus
                  onClick={() => {
                    append({
                      color: "",
                      size: "",
                      images: [],
                      price: 0,
                      stock: 0,
                    });
                  }}
                />
                Add Variant
              </Grid>

              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                  paddingRight={3}
                  paddingLeft={3}
                  marginTop={2}
                  marginBottom={2}
                >
                  {fields.map(({ id }, index) => {
                    return (
                      <>
                        <Grid item xs={12} md={6} key={id}>
                          {/* Color */}
                          <FormControl fullWidth>
                            <InputLabel id="color-type-select-label">
                              {`Color - ${index + 1}`}
                            </InputLabel>
                            <Controller
                              control={control}
                              // @ts-ignore
                              name={`variants[${index}].color`}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Color is required",
                                },
                              }}
                              render={({ field }) => (
                                <Select
                                  fullWidth
                                  id="variants"
                                  label={`Color - ${index + 1}`}
                                  size="medium"
                                  placeholder="Color"
                                  error={!!errors.subCategory}
                                  {...field}
                                >
                                  {[
                                    colors?.data?.map((color: any) => (
                                      <MenuItem
                                        key={color._id}
                                        value={color._id}
                                      >
                                        {color.name}
                                      </MenuItem>
                                    )),
                                  ]}
                                </Select>
                              )}
                            />
                            <FormHelperText error={!!errors.variants}>
                              {errors.variants?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} key={id}>
                          {/* Size */}
                          <FormControl fullWidth>
                            <InputLabel id="size-type-select-label">
                              {`Size - ${index + 1}`}
                            </InputLabel>
                            <Controller
                              control={control}
                              // @ts-ignore
                              name={`variants[${index}].size`}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Size is required",
                                },
                              }}
                              render={({ field }) => (
                                <Select
                                  fullWidth
                                  id="variants"
                                  label={`Size - ${index + 1}`}
                                  size="medium"
                                  placeholder="Size"
                                  error={!!errors.subCategory}
                                  {...field}
                                >
                                  {[
                                    sizes?.data?.map((size: any) => (
                                      <MenuItem key={size._id} value={size._id}>
                                        {size.size}
                                      </MenuItem>
                                    )),
                                  ]}
                                </Select>
                              )}
                            />
                            <FormHelperText error={!!errors.variants}>
                              {errors.variants?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          {/* Variant Price */}
                          <FormControl fullWidth>
                            <Controller
                              // @ts-ignore
                              name={`variants[${index}].price`}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Price is required",
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  {...field}
                                  label="Price"
                                  inputProps={{
                                    inputMode: "numeric",
                                  }}
                                  type="number"
                                  error={!!errors.price}
                                />
                              )}
                            />
                            <FormHelperText error={!!errors.price}>
                              {errors.price?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          {/* Variant Stock */}
                          <FormControl fullWidth>
                            <Controller
                              // @ts-ignore
                              name={`variants[${index}].stock`}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Variant Stock is required",
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  {...field}
                                  label="Variant Stock"
                                  inputProps={{
                                    inputMode: "numeric",
                                  }}
                                  type="number"
                                  error={!!errors.variants}
                                />
                              )}
                            />
                            <FormHelperText error={!!errors.variants}>
                              {errors.variants?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        {/* Image */}
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <FileUploader
                              accept="image/*"
                              handleChange={(files: any) => {
                                handleVariantImageChange(files, index);
                              }}
                              types={["JPEG", "PNG"]}
                              multiple
                            >
                              <div className="file-uploader-body">
                                <button className="btn">Add Images</button>
                                <p>Accepts .png and .jpg</p>
                              </div>
                            </FileUploader>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Button
                            type="button"
                            size="large"
                            variant="outlined"
                            color="error"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </Button>
                        </Grid>
                        <br />
                      </>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} padding={1} margin={1}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Button
                  type="button"
                  size="large"
                  variant="outlined"
                  color="error"
                  onClick={() => navigte("/products")}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="basePrimary"
                >
                  Add Product
                </Button>
              </Stack>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

type BasicDetailsFormProps = {
  control: Control<StockProductFormData, any>;
  categoryName: string;
  subcategories: any;
  tags: any;
  errors: any;
};

const BasicDetailsForm = ({
  control,
  categoryName,
  subcategories,
  tags,
  errors,
}: BasicDetailsFormProps) => (
  <Grid
    container
    rowSpacing={3}
    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      marginTop: "20px",
    }}
  >
    <Grid item className="header" md={12} xs={12}>
      <CgFileDocument />
      Basic Details
    </Grid>

    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        paddingRight={3}
        paddingLeft={3}
        marginTop={2}
        marginBottom={2}
      >
        {/* Category */}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="category-type-select-label">Category</InputLabel>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Select
                  disabled
                  renderValue={() => categoryName}
                  size="medium"
                  label="Category"
                  labelId="category-type-select-label"
                  id="category-type-select"
                  {...field}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Subcategory */}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="subCategory-type-select-label">
              Subcategory
            </InputLabel>
            <Controller
              name="subCategory"
              control={control}
              render={({ field }) => (
                <Select
                  size="medium"
                  placeholder="Subcategory"
                  id="subCategory-type-select"
                  label="Subcategory"
                  labelId="subCategory-type-select-label"
                  error={!!errors.subCategory}
                  {...field}
                >
                  {[
                    subcategories?.map((subcategory: any) => (
                      <MenuItem key={subcategory._id} value={subcategory._id}>
                        {subcategory.name}
                      </MenuItem>
                    )),
                  ]}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        {/* Tags */}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="tags-type-select-label">Tags</InputLabel>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <Select
                  size="medium"
                  placeholder="Tags"
                  id="tags-type-select"
                  label="Tags"
                  labelId="tags-type-select-label"
                  error={!!errors.tags}
                  multiple
                  {...field}
                >
                  {[
                    tags?.map((tags: any) => (
                      <MenuItem key={tags._id} value={tags._id}>
                        {tags.name}
                      </MenuItem>
                    )),
                  ]}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          {/* Name Arabic */}
          <FormControl fullWidth>
            <Controller
              name="nameAr"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Arabic name is required",
                },
              }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...field}
                  label="Product name (Arabic)"
                  inputProps={{
                    inputMode: "text",
                  }}
                  type="string"
                  placeholder=""
                  error={!!errors.nameAr}
                />
              )}
            />
            <FormHelperText error={!!errors.nameAr}>
              {errors.nameAr?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          {/* Name English */}
          <FormControl fullWidth>
            <Controller
              name="nameEn"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "English name is required",
                },
              }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...field}
                  label="Product Name (English)"
                  inputProps={{
                    inputMode: "text",
                  }}
                  type="string"
                  placeholder=""
                  error={!!errors.nameEn}
                />
              )}
            />
            <FormHelperText error={!!errors.nameEn}>
              {errors.nameEn?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* SKU */}
          <FormControl fullWidth>
            <Controller
              name="sku"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "SKU is required",
                },
              }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...field}
                  label="SKU"
                  inputProps={{
                    inputMode: "text",
                  }}
                  type="string"
                  placeholder=""
                  error={!!errors.sku}
                />
              )}
            />
            <FormHelperText error={!!errors.sku}>
              {errors.sku?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12}>
          {/* Description Arabic */}
          <FormControl fullWidth>
            <Controller
              name="descriptionAr"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Arabic description is required",
                },
              }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...field}
                  label="Product description (Arabic)"
                  inputProps={{
                    inputMode: "text",
                  }}
                  multiline
                  maxRows={4}
                  type="string"
                  placeholder=""
                  error={!!errors.descriptionAr}
                />
              )}
            />
            <FormHelperText error={!!errors.descriptionAr}>
              {errors.descriptionAr?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12}>
          {/* Description English */}
          <FormControl fullWidth>
            <Controller
              name="descriptionEn"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "English description is required",
                },
              }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...field}
                  label="Product description (English)"
                  inputProps={{
                    inputMode: "text",
                  }}
                  multiline
                  maxRows={4}
                  type="string"
                  placeholder=""
                  error={!!errors.descriptionEn}
                />
              )}
            />
            <FormHelperText error={!!errors.descriptionEn}>
              {errors.descriptionEn?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  </Grid>
);
