import instance from "../../../utils/axios.util";
import { Product } from "../../models";

interface CreateProductProps {
  storeId: string;
  body: Product;
}

export async function CreateProduct({ storeId, body }: CreateProductProps) {
  try {
    const res = await instance.post(`${storeId}/product`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const getStoreProducts = async (storeId: string) => {
  try {
    const res = await instance.get(`${storeId}/product`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
