import { useEffect, useState } from "react";

//Icons
import { IoIosArrowDown } from "react-icons/io";

//Styles
import "./style.scss";

type SelectBoxProps = {
  getData: () => any;
  name?: string;
  value?: string;
  label?: string;
  placeHolder?: string;
  onChange?: (value: any) => void;
  isEditView?: boolean;
};

type Selected = [
  {
    name: string;
    _id: string;
  }
];

function SelectBox({
  name,
  value,
  label,
  placeHolder,
  onChange,
  isEditView,
  getData,
}: SelectBoxProps) {
  const [selected, setSelected] = useState<Selected>([{ name: "", _id: "" }]);

  useEffect(() => {
    const fetch = async () => {
      const data = getData();
      setSelected(data);
    };
    fetch();
  }, []);

  return (
    <div className="selectBoxContainer">
      {label && <label>{label}</label>}
      <select
        disabled={isEditView}
        name={name}
        defaultValue={value}
        onChange={onChange}
      >
        <option disabled value="">
          {placeHolder || "Select"}
        </option>
        {selected?.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      <IoIosArrowDown size={15} fill={"#c5c6cc"} />
    </div>
  );
}

export default SelectBox;
