import { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useListProducts } from "../../@core/hooks";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { FilterHeader, NavBar, Table } from "../../components";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";

//Icons
import { AiOutlineEye } from "react-icons/ai";

type Product = {
  _id: string;
  name: string;
  sku: string;
  category: {
    name: string;
  };
  status: string;
};

function Products() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { merchant } = useCurrentMerchantSelector();
  const [page, setPage] = useState(0);

  const {
    data: products,
    isLoading: isProductsLoading,
    refetch: RefetchProductList,
    isRefetching,
  } = useListProducts({
    storeId: merchant!!._id,
    limit: 10,
    page,
  });

  useEffect(() => {
    RefetchProductList();
  }, [page]);

  const columns = useMemo(
    () => [
      {
        title: t("product.name"),
        selector: (row: Product) => row.name,
      },
      {
        title: t("product.sku"),
        selector: (row: Product) => row.sku,
      },
      {
        title: t("product.category"),
        selector: (row: Product) => row.category.name,
      },
      {
        title: t("product.status"),
        selector: (row: Product) => (
          <span
            className={`${
              row.status === "Pending"
                ? "pendingStatus"
                : row.status === "Approved"
                ? "approvedStatus"
                : row.status === "Rejected"
                ? "cancelledStatus"
                : ""
            }`}
          >
            {row.status}
          </span>
        ),
      },
      {
        title: t("product.action"),
        selector: (row: Product) => (
          <Link to={`${row._id}`}>
            <AiOutlineEye size={20} />
          </Link>
        ),
      },
    ],
    [t]
  );

  if (isProductsLoading || isRefetching)
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.products")} />
        <FilterHeader
          hasSearch={false}
          hasStatus={false}
          hasAdd
          iconTitle={t("product.addProduct")}
        />
        <div className="tableContainer" id="tableContainer">
          <Table columns={columns} data={[]}>
            <LoadingSpinner />
          </Table>
        </div>
      </Fragment>
    );

  return (
    <div>
      <NavBar title={t("menuNavigation.products")} />
      <FilterHeader
        hasSearch={false}
        hasStatus={false}
        hasAdd
        iconTitle={t("product.addProduct")}
        onAdd={() =>
          navigate(
            `/products/create/${
              merchant!!.type !== "Food" ? "stock" : "non-stock"
            }`
          )
        }
      />
      <div className="tableContainer" id="tableContainer">
        <Table
          columns={columns}
          data={products!!.data as any[]}
          handlePageChange={(selectedItem) => setPage(selectedItem.selected)}
          pageCount={products!!.numberOfPages}
          initialPage={page}
        />
      </div>
    </div>
  );
}

export default Products;
