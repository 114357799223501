import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import {
	Controller,
	Control,
	RegisterOptions,
} from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormHelperText } from '@mui/material';


interface Props extends React.HTMLProps<HTMLDivElement> {
	name: string;
	label: string;
	control?: Control<any>;
	error?: any;
	rules?:
		| Omit<
				RegisterOptions<any, string>,
				| 'valueAsNumber'
				| 'valueAsDate'
				| 'setValueAs'
				| 'disabled'
		  >
		| undefined;
}

export const DatePickerInput: React.FC<Props> = ({name, control, label, error, rules = {}}) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<LocalizationProvider
					dateAdapter={AdapterDayjs}>
					<DatePicker
						{...field}
						format='DD/MM/YYYY'
						label={label}
						slotProps={{
							textField: {
								error: !!error,
							},
						}}
					/>
					<FormHelperText error={!!error}>
						{ error?.message }
					</FormHelperText>
				</LocalizationProvider>
			)}
		/>
	);
}