import { Box, Button, FormHelperText, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  useCreateDiscount,
  useListProducts,
  useStore,
} from "../../@core/hooks";
import { useSubcategories } from "../../@core/hooks/query/discount/get-sub-categories.hook";
import { DiscountFormData } from "../../@core/models";
import { StoreDiscountTypesEnum } from "../../@core/models/discount/discount-types.enum";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { DatePickerInput } from "../../components/date-picker/date-picker-input.component";
import { TimePickerInput } from "../../components/time-picker/time-picker.component";
import { addTimeToDate } from "../../utils/dayjs.util";
import { PercentageNumber } from "../../utils/regex-patterns.util";
import { StoreDiscountTypes } from "./discount-types";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
}

export const AddDiscountForm: React.FC<Props> = ({ closeModal }) => {
  const { data: store } = useStore();
  const { mutate: createDiscount } = useCreateDiscount();
  const { data: productsList } = useListProducts({
    storeId: store?.data._id!,
    page: 1,
    limit: undefined,
    paginated: false,
  });
  const { merchant } = useCurrentMerchantSelector();
  const { data: subCategories } = useSubcategories(store?.data.category._id!);
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<DiscountFormData>({
    defaultValues: {
      type: "",
      store: store?.data._id,
      discount: undefined,
      image: undefined,
      category: store?.data.category._id!,
      subCategory: "",
      product: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
    },
  });

  const typeChange = watch("type");
  const watchImage = watch("image");

  const handleImageChange = (file: File) => {
    setValue("image", file);
  };

  const onSubmit = (data: DiscountFormData) => {
    const startDate = addTimeToDate(
      data.startDate,
      data.startTime
    ).toISOString();

    const endDate = addTimeToDate(data.startDate, data.startTime).toISOString();

    // remove startTime and endTime from data
    delete data.startTime;
    delete data.endTime;

    const body = {
      ...data,
      store: store?.data._id,
      discount: +data.discount!,
      startDate,
      endDate,
    };

    if (StoreDiscountTypesEnum.STORE_DISCOUNT) {
      delete body.category;
      delete body.subCategory;
      delete body.product;
    }

    if (StoreDiscountTypesEnum.CATEGORY_DISCOUNT) {
      delete body.subCategory;
      delete body.product;
    }

    if (StoreDiscountTypesEnum.CATEGORY_DISCOUNT) {
      delete body.category;
      delete body.subCategory;
    }

    const formData = new FormData();

    Object.entries(body).forEach(([key, value]) => {
      formData.set(key, value);
    });

    queryClient.invalidateQueries([`${merchant?._id}/discounts`, []]);

    console.log("[merchant]", [`${merchant?._id}/discounts`, []]);

    createDiscount(
      {
        storeId: store?.data._id!,
        body: formData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [`discount/store/${merchant?._id}`, []],
          });

          closeModal();
        },
        onError: (error) => {
          console.error(error);
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            {/* Discount Type */}
            <FormControl fullWidth>
              <InputLabel id="discount-type-select-label">
                Discount Type
              </InputLabel>
              <Controller
                name="type"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Discount Type is required",
                  },
                }}
                render={({ field }) => (
                  <Select
                    id="discount-type-select"
                    label="Discount Type"
                    labelId="discount-type-select-label"
                    error={!!errors.type}
                    {...field}
                  >
                    {StoreDiscountTypes?.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText error={!!errors.type}>
                {errors.type?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Discount Amount */}
            <FormControl fullWidth>
              <Controller
                name="discount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Discount Amount is required",
                  },
                  pattern: {
                    value: PercentageNumber,
                    message: "Amount must be a number between 0 and 100",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Discount Amount"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    type="number"
                    placeholder="10%"
                    error={!!errors.discount}
                  />
                )}
              />
              <FormHelperText error={!!errors.discount}>
                {errors.discount?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          {typeChange === StoreDiscountTypesEnum.SUB_CATEGORY_DISCOUNT && (
            <>
              {/* Category */}
              {/* <Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id='product-category-select-label'>
										Category
									</InputLabel>

									<Controller
										name='category'
										control={control}
										rules={{
											required: {
												value: true,
												message:
													'Category is required',
											},
										}}
										render={({
											field,
										}) => (
											<>
												<Select
													labelId='category-select-label'
													id='category-select'
													label='Category'
													error={
														!!errors.product
													}
													{...field}>
													{categoryOptions?.map(
														(
															category
														) => (
															<MenuItem
																key={
																	category.value
																}
																value={
																	category.value
																}>
																{
																	category.label
																}
															</MenuItem>
														)
													)}
												</Select>

												<FormHelperText
													error={
														!!errors.product
													}>
													{
														errors
															.product
															?.message
													}
												</FormHelperText>
											</>
										)}
									/>
								</FormControl>
							</Grid> */}

              <Grid item xs={12}>
                {/* Sub Category */}
                <FormControl fullWidth>
                  <InputLabel id="sub-category-select-label">
                    Sub Category
                  </InputLabel>
                  <Controller
                    name="subCategory"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <>
                        <Select
                          labelId="sub-category-select-label"
                          id="sub-category-select"
                          label="Sub Category"
                          error={!!errors.product}
                          {...field}
                        >
                          {subCategories?.data?.map((category: any) => (
                            <MenuItem
                              key={category?.value}
                              value={category?.value}
                            >
                              {category?.label}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText error={!!errors.product}>
                          {errors.product?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}

          {typeChange === StoreDiscountTypesEnum.PRODUCT_DISCOUNT && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="product-select-label">Product</InputLabel>
                <Controller
                  name="product"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <>
                      <Select
                        labelId="product-select-label"
                        id="product-select"
                        label="Sub Category"
                        error={!!errors.product}
                        {...field}
                      >
                        {productsList?.data.map((product: any) => (
                          <MenuItem key={product?._id} value={product?._id}>
                            {product?.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error={!!errors.product}>
                        {errors.product?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <DatePickerInput
                name="startDate"
                label="Start Date"
                rules={{
                  required: {
                    value: true,
                    message: "Start Date is required",
                  },
                }}
                control={control}
                error={errors.startDate}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TimePickerInput
                name="startTime"
                label="Start Time"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Start Time is required",
                  },
                }}
                error={errors.startTime}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <DatePickerInput
                name="endDate"
                label="End Date"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "End Date is required",
                  },
                }}
                error={errors.endDate}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TimePickerInput
                name="endTime"
                label="End Time"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "End Time is required",
                  },
                }}
                error={errors.endTime}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <InputLabel variant="standard">Add Discount Image</InputLabel>
            <FormControl fullWidth>
              <FileUploader
                accept="image/*"
                handleChange={handleImageChange}
                types={["JPEG", "PNG", "GIF"]}
              >
                <div className="file-uploader-body">
                  <button className="btn">Add Files</button>
                  {watchImage instanceof File ? (
                    <p>File name: {watchImage?.name}</p>
                  ) : (
                    <img src={watchImage} alt="discount" />
                  )}
                  <p>Accepts .gif, .png and .jpg</p>
                </div>
              </FileUploader>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >
              <Button type="button" size="large" variant="outlined">
                Cancel
              </Button>

              <Button type="submit" size="large" variant="contained">
                Add Discount
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
