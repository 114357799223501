import { useState } from "react";

//Styles
import "./style.scss";

//Icons
import { BiArrowBack } from "react-icons/bi";
import { CgLogOut } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

//Hooks
import { useDispatch } from "react-redux";
import { logOut, useCurrentUserSelector } from "../../@core/slice";
import { LanguageSwitcher } from "../lang/language-switcher";
import { useTranslation } from "react-i18next";

type NavbarProps = {
  title: string;
  status?: string;
  orderPaid?: boolean;
  hasBack?: boolean;
};

function NavBar({
  title,
  status,
  hasBack = false,
  orderPaid = false,
}: NavbarProps) {
  const navigate = useNavigate();
  const [shown, setShown] = useState(false);
  const { user } = useCurrentUserSelector();

  const { t, i18n } = useTranslation();

  const isRTL = i18n.dir() === "rtl";

  const dispatch = useDispatch();

  return (
    <div className="navbarContainer">
      <div>
        {hasBack && (
          <div onClick={() => navigate(-1)}>
            <BiArrowBack size={20} />
          </div>
        )}
        <h2>{title}</h2>
        {status && (
          <span
            className={`${
              status === "Pending"
                ? "pendingStatus"
                : status === "Delivered"
                ? "approvedStatus"
                : status === "Accepted" || status === "Packing"
                ? "acceptedStatus"
                : status === "Approved"
                ? "approvedStatus"
                : status === "Cancelled"
                ? "cancelledStatus"
                : ""
            }`}
          >
            {status}
          </span>
        )}
        {orderPaid && (
          <span className="approvedStatus">{t("navigationBar.paid")}</span>
        )}
      </div>
      <div>
        <div>
          <LanguageSwitcher />
        </div>
        <div className="profile">
          <div onClick={() => setShown(!shown)}>
            <div>{`${user?.user?.name?.split(" ")[0]?.charAt(0)}${
              user?.user?.name?.split(" ")[1]?.charAt(0) ?? ""
            }`}</div>
            <h3>{user?.user?.name}</h3>

            {isRTL ? (
              <CgLogOut onClick={() => dispatch(logOut())} size={26} />
            ) : (
              <CgLogOut
                onClick={() => dispatch(logOut())}
                style={{ transform: "rotate(180deg)" }}
                size={26}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
