import { NavLink, Outlet } from "react-router-dom";

//Styles
import "./style.scss";

//Icons
import { HiOutlineInbox } from "react-icons/hi";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineLocalOffer } from "react-icons/md";
import { TbDiscount2 } from "react-icons/tb";
import { VscSettings } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

function MenuBar() {
  const { t } = useTranslation();

  return (
    <>
      <div className="menubarContainer">
        <div>
          <img src="/logo.png" alt="logo" />
          <span>BASE</span>
        </div>
        <div className="menuContainer">
          <NavLink
            aria-activedescendant="active"
            className="menuItem"
            to="/products"
          >
            <MdOutlineLocalOffer size={20} />
            <span>{t("menuNavigation.products")}</span>
          </NavLink>
          <NavLink
            aria-activedescendant="active"
            className="menuItem"
            to="/orders"
          >
            <HiOutlineInbox size={20} />
            <span>{t("menuNavigation.orders")}</span>
          </NavLink>
          <NavLink
            aria-activedescendant="active"
            className="menuItem"
            to="/employees"
          >
            <IoPersonOutline size={20} />
            <span>{t("menuNavigation.employees")}</span>
          </NavLink>
          <NavLink
            aria-activedescendant="active"
            className="menuItem"
            to="/discount"
          >
            <TbDiscount2 size={20} />
            <span>{t("menuNavigation.discounts")}</span>
          </NavLink>
          <NavLink aria-activedescendant="active" className="menuItem" to="/">
            <VscSettings size={20} />
            <span>{t("menuNavigation.prefrences")}</span>
          </NavLink>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default MenuBar;
