import { useListCategory } from '../../@core/hooks/query/category';
import { StoreFormData } from '../../@core/models';
import { useCreateStore } from '../../@core/hooks';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setStore } from '../../@core/slice';
import { AddStoreFormBody } from './add-store-form.hoc';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLProps<HTMLDivElement> {
	closeModal: () => void;
}

export const AddStoreForm: React.FC<Props> = ({
	closeModal,
}) => {
	const { data: categories } = useListCategory();
	const { mutate: createStore } = useCreateStore();
	const navigte = useNavigate();
	const dispatch = useDispatch();
	const { i18n } = useTranslation();

	const categoryOptions = categories?.data?.map(
		(category) => ({
			value: category._id,
			label: category.name,
		})
	);

	const mapDayIndexToDayName = (dayIndex: number) => {
		const days: any[] = [
			{
				en: 'sunday',
				ar: 'الاحد',
			},
			{
				en: 'monday',
				ar: 'الاثنين',
			},
			{
				en: 'tuesday',
				ar: 'الثلاثاء',
			},
			{
				en: 'wednesday',
				ar: 'الاربعاء',
			},
			{
				en: 'thursday',
				ar: 'الخميس',
			},
			{
				en: 'friday',
				ar: 'الجمعة',
			},
			{
				en: 'saturday',
				ar: 'السبت',
			},
		];

		return days[dayIndex]?.[
			i18n.language === 'en' ? 'en' : 'ar'
		];
	};

	const onSubmit = (values: StoreFormData) => {
		const workingHours = values.workingHours.map(
			(date: any) => {
				return {
					day: date.day,
					operationTimeStart: dayjs(
						date.operationTimeStart
					).toISOString(),
					operationTimeEnd: dayjs(
						date.operationTimeEnd
					).toISOString(),
				};
			}
		);

		console.log({
			...values,
			workingHours,
		});

		const formData = new FormData();

		Object.entries(values).forEach(([key, value]) => {
			if (key === 'workingHours') {
				// value.forEach((date: any) => {
				// 	formData.append(
				// 		key,
				// 		JSON.stringify(date)
				// 	);
				// });
				formData.append(key, JSON.stringify(value));
				return;
			}

			formData.set(key, value);
		});

		createStore(formData as any, {
			onSuccess: (data) => {
				dispatch(setStore(data));
				toast.success('Store added successfully');
				navigte('/products');
			},
			onError: () => {
				toast.error(
					'Failed to add store, please try again'
				);
			},
		});
	};

	return (
		<AddStoreFormBody
			closeModal={closeModal}
			submitHandler={onSubmit}
			categoryOptions={categoryOptions}
			mapDayIndexToDayName={mapDayIndexToDayName}
		/>
	);
};
