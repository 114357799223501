import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useCreateEmployee } from "../../../@core/hooks/mutation/employee/use-create-employee";
import { ICreateEmployeeDto } from "../../../@core/models";
import { useCurrentMerchantSelector } from "../../../@core/slice";
import { theme } from "../../../assets/styles/mui-theme";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import CustomePhoneNumber from "../../signup/components/PhoneNumber";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
}

export const AddEmployeeForm: React.FC<Props> = ({ closeModal }) => {
  const { merchant } = useCurrentMerchantSelector();

  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateEmployeeDto>({
    defaultValues: {
      name: "",
      email: "",
      countryCode: "+965",
      phoneNumber: "",
      password: "",
      gender: "",
      dateOfBirth: "",
      job: "",
    },
  });

  const { mutate: createEmployee } = useCreateEmployee();

  const onSubmit = (values: ICreateEmployeeDto) => {
    if (values.gender === "") delete values.gender;
    if (values.dateOfBirth === "") delete values.dateOfBirth;

    const body = {
      email: values.email,
      password: values.password,
      name: values.name,
      countryCode: values.phoneNumber.substring(0, 4),
      phoneNumber: values.phoneNumber.substring(4),
      job: values.job,
    };

    createEmployee(
      {
        body,
        storeId: merchant!!._id,
      },
      {
        onSuccess: (data) => {
          toast.success("Employee successfully created");
          queryClient.invalidateQueries({
            queryKey: [`employee/store/${merchant?._id}`, []],
          });

          closeModal();
        },
        onError: () => {
          toast.error("Failed to create employee");
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            {/* Employee Name */}
            <FormControl fullWidth>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Employee Name is required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Employee Name"
                    inputProps={{
                      inputMode: "text",
                    }}
                    type="string"
                    error={!!errors.name}
                  />
                )}
              />
              <FormHelperText error={!!errors.name}>
                {errors.name?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Employee Email */}
            <FormControl fullWidth>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Employee Email is required",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Employee Email"
                    inputProps={{
                      inputMode: "email",
                    }}
                    type="string"
                    error={!!errors.email}
                  />
                )}
              />
              <FormHelperText error={!!errors.email}>
                {errors.email?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Employee Job */}
            <FormControl fullWidth>
              <Controller
                name="job"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Employee Job is required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Employee Job"
                    inputProps={{
                      inputMode: "text",
                    }}
                    type="string"
                    error={!!errors.job}
                  />
                )}
              />
              <FormHelperText error={!!errors.job}>
                {errors.job?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Phone number is required",
                  },
                  validate: (value) => isValidPhoneNumber(value),
                }}
                render={({ field: { value, onChange } }) => (
                  <PhoneInput
                    inputComponent={CustomePhoneNumber}
                    defaultCountry="KW"
                    value={value}
                    onChange={onChange}
                    id="phoneNumber"
                  />
                )}
              />
              <FormHelperText error={!!errors.phoneNumber}>
                {errors.phoneNumber?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                  pattern: {
                    // regex to validate password with at least one lowercase letter, one uppercase letter, one digit, one special character, and is at least 8 characters long
                    value:
                      /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})$/,
                    message:
                      "Password must be at least one lowercase letter, one uppercase letter, one digit, one special character, and is at least 8 characters long",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    label="Password"
                    inputProps={{
                      inputMode: "text",
                    }}
                    type="password"
                    placeholder=""
                    error={!!errors.password}
                  />
                )}
              />
              <FormHelperText error={!!errors.password}>
                {errors.password?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >
              <Button
                type="button"
                size="large"
                variant="outlined"
                color="error"
                onClick={closeModal}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                size="large"
                variant="contained"
                color="basePrimary"
              >
                Add Employee
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
