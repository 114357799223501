import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useCurrentUserSelector } from "./@core/slice";
import { ReactQueryDevtools } from "react-query/devtools";
import { MenuBar } from "./components";
import Error from "./components/404/404";
import ProtectedRoute from "./components/protected/protectedRoute";
import "react-toastify/dist/ReactToastify.css";
import {
  Discount,
  Employees,
  ForgetPassword,
  Login,
  Orders,
  Preferences,
  Products,
  ResetConfirmation,
  ResetPassword,
  SignUp,
} from "./pages";
import { ViewOrder } from "./pages/orders/components";
import { ViewProduct } from "./pages/products/components";
import { AddStockProductForm } from "./components/StockProduct/add-stock-product-form.component";
import { EditStockProductForm } from "./components/StockProduct/edit-stock-product-form";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
});

// redeploy 2

const App = () => {
  const { user } = useCurrentUserSelector();

  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "ar") {
      document.querySelector("html")?.setAttribute("dir", "rtl");
      document.querySelector("html")?.setAttribute("lang", "ar");
      document.querySelector("body")?.classList.add("body-rtl");
    } else {
      document.querySelector("html")?.setAttribute("dir", "ltr");
      document.querySelector("html")?.setAttribute("lang", "en");
      document.querySelector("body")?.classList.remove("body-rtl");
    }
  }, [i18n.language]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-confirmation" element={<ResetConfirmation />} />
          <Route
            path="/"
            element={
              <ProtectedRoute user={user ? true : false}>
                <MenuBar />
              </ProtectedRoute>
            }
          >
            <Route index element={<Preferences />} />
            <Route
              path="/products"
              element={
                <ProtectedRoute user={user ? true : false}>
                  <MenuBar />
                </ProtectedRoute>
              }
            >
              <Route index element={<Products />} />
              <Route path=":productId" element={<ViewProduct />} />
              <Route
                path="edit/:productId/:type"
                element={<EditStockProductForm />}
              />
              <Route path="create/:type" element={<AddStockProductForm />} />
            </Route>
            <Route
              path="/orders"
              element={
                <ProtectedRoute user={user ? true : false}>
                  <MenuBar />
                </ProtectedRoute>
              }
            >
              <Route index element={<Orders />} />
              <Route path=":orderId" element={<ViewOrder />} />
            </Route>
            <Route
              path="/employees"
              element={
                <ProtectedRoute user={user ? true : false}>
                  <MenuBar />
                </ProtectedRoute>
              }
            >
              <Route index element={<Employees />} />
            </Route>
            <Route path="/discount" element={<Discount />} />
            <Route path="/prefrences" element={<Preferences />} />
            <Route path="/*" element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
