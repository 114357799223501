import { Link } from "react-router-dom";
import "./style.scss";

const Error: React.FC = () => {
  return (
    <div className="error" id="error">
      <h1>404</h1>
      <h2>Page not found</h2>
      <p>
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable.
      </p>
      <Link to="/">Go to Home</Link>
    </div>
  );
};

export default Error;
