import { ListTagResponse } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

type Props = {
  categoryId: string;
};

export const useListTag = ({ categoryId }: Props) =>
  useCustomQuery<ListTagResponse>({
    url: `tag/${categoryId}?paginate=false&categoryId=`,
    dependedKey: "categoryId",
    dependedParams: [categoryId],
  });
