import axios from '../../../utils/axios.util';
import { Category } from '../../models';
import { Page } from '../../models/page/page.model';

interface GetCategoriesParams {
	skip?: number;
	limit?: number;
	order?: 'ASCENDING' | 'DESCENDING';
	search?: string;
	paginate?: boolean;
}

export const getAllCategories = (getCategoryParams: GetCategoriesParams) => {
	return axios.get<Page<Category[]>>(`/seller/category`, {
		params: getCategoryParams,
	});
}

export const getSubcategories = async (mainCategoryId: string) => {
	try {
		const res = await axios.get<Page<any>>(
			`/category/${mainCategoryId}/sub-category`
		);
		return res.data;
	} catch (error) {
		console.error(error);
	}
}
