import { ListOrderResponse } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface Props {
  storeId: string;
  page: number;
  limit: number;
}

export const useListOrders = ({ storeId, page, limit }: Props) =>
  useCustomQuery<ListOrderResponse>({
    url: `${storeId}/order`,
    page,
    limit,
  });
