import { useState } from "react";
import { InputBox } from "../../components";

//styles
import "./style.scss";

//icons

type ResetPasswordFormObj = {
  password: string;
  confirmPassword: string;
};

function ResetPassword() {
  const [formObj, setFormObj] = useState<ResetPasswordFormObj>({
    password: "",
    confirmPassword: "",
  });
  return (
    <div className="resetPasswordContainer">
      <div className="container">
        <div className="logo">
          <img src="/logoDark.png" alt="logo" />
          <span>BASE</span>
        </div>
        <div>
          <h3>Reset Password</h3>
        </div>
        <div className="formContainer">
          <form>
            <InputBox
              type="password"
              placeholder="Password"
              value={formObj.password}
              onChange={(password: string) =>
                setFormObj({ ...formObj, password })
              }
            />
            <InputBox
              type="password"
              placeholder="Confirm Password"
              value={formObj.password}
              onChange={(password: string) =>
                setFormObj({ ...formObj, password })
              }
            />
            <button>Reset</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
