import instance from '../../../utils/axios.util';

interface CreateDiscountProps {
	storeId: string;
	body: FormData;
}

export async function CreateDiscount({
	storeId,
	body,
}: CreateDiscountProps) {
	try {
		const res = await instance.post(
			`${storeId}/discount`,
			body
		);
		return res.data;
	} catch (error) {
		throw error;
	}
}

export const editDiscountById = async ({
	discountId,
	storeId,
	body,
}: {
	discountId: string;
	storeId: string;
	body: any;
}) => {
	return await instance.put(
		`${storeId}/discount/${discountId}`,
		body
	);
};

export const getDiscountById = async ({
	storeId,
	discountId,
}: {
	storeId: string;
	discountId: string;
}) => {
	try {
		const res = await instance.get(
			`${storeId}/discount/${discountId}`
		);
		return res.data.data;
	} catch (error) {
		throw error;
	}
};
