import { Fragment } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { TbSeparator } from "react-icons/tb";
import ReactPaginate from "react-paginate";

//Styles
import "./style.scss";

type TableProps = {
  columns: { title: string; selector: any }[];
  data: any[];
  handlePageChange?: (selectedItem: { selected: number }) => void;
  pageCount?: number;
  initialPage?: number;
  children?: any;
};

function Table({
  columns,
  data,
  handlePageChange,
  pageCount,
  initialPage,
  children,
}: TableProps) {
  return (
    <Fragment>
      <>
        {children ? (
          <Fragment>
            <table id="table">
              <thead>
                <tr>
                  {columns.map((column, key) => (
                    <th className="tableHead" key={key}>
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
            {children}
          </Fragment>
        ) : (
          <Fragment>
            <table id="table">
              <thead>
                <tr>
                  {columns.map((column, key) => (
                    <th className="tableHead" key={key}>
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, key) => (
                  <tr key={key}>
                    {columns.map(({ selector }, key) => {
                      const item = selector(row);
                      return <td key={key}>{item}</td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            
            <ReactPaginate
              className="reactPaginate"
              containerClassName="container"
              pageClassName="page"
              breakClassName="break"
              breakLinkClassName="breakLink"
              activeClassName="active"
              activeLinkClassName="activeLink"
              disabledClassName="disabled"
              nextClassName="next"
              nextLinkClassName="nextLink"
              pageLinkClassName="pageLink"
              previousClassName="previous"
              previousLinkClassName="previousLink"
              // : Fix this
              marginPagesDisplayed={2}
              breakLabel={<TbSeparator size={45} />}
              nextLabel={<IoIosArrowForward size={25} />}
              pageRangeDisplayed={4}
              pageCount={pageCount === 1 ? 0 : pageCount || 0}
              previousLabel={<IoIosArrowBack size={25} />}
              renderOnZeroPageCount={null}
              disableInitialCallback={true}
              initialPage={initialPage}
              onPageChange={handlePageChange}
            />
          </Fragment>
        )}
      </>
    </Fragment>
  );
}

export default Table;
