import {
	Box,
	Grid,
	FormControl,
	TextField,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem,
	Stack,
	Button,
} from '@mui/material';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import {
	Controller,
	useFieldArray,
	useForm,
} from 'react-hook-form';
import { StoreFormData } from '../../@core/models';
import { TimePickerInput } from '../../components/time-picker/time-picker.component';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLProps<HTMLDivElement> {
	submitHandler: (values: StoreFormData) => void;
	closeModal: () => void;
	categoryOptions?: {
		value: string;
		label: string;
	}[];
	mapDayIndexToDayName: (
		dayIndex: number,
		lang?: 'ar' | 'en'
	) => string;
}

export const AddStoreFormBody: React.FC<Props> = ({
	submitHandler: onSubmit,
	categoryOptions,
	closeModal,
	mapDayIndexToDayName,
}) => {
	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm<StoreFormData>({
		defaultValues: {
			category: '',
			nameAr: '',
			nameEn: '',
			descriptionAr: '',
			descriptionEn: '',
			image: undefined,
			workingHours: Array.from(
				{ length: 7 },
				(_, index) => ({
					day: index,
					operationTimeStart: undefined,
					operationTimeEnd: undefined,
				})
			),
			longitude: 47.4818,
			latitude: 29.3117,
			returnPolicyAr: '',
			exchangePolicyAr: '',
			returnPolicyEn: '',
			exchangePolicyEn: '',
		},
	});

	const workingHoursFields = useFieldArray({
		control,
		name: 'workingHours',
	});

	const watchImage = watch('image');

	const handleImageChange = (file: File) => {
		setValue('image', file);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box>
				<Grid
					container
					rowSpacing={3}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={12} md={6}>
						{/* Name Arabic */}
						<FormControl fullWidth>
							<Controller
								name='nameAr'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'Arabic name is required',
									},
								}}
								render={({ field }) => (
									<TextField
										size='small'
										{...field}
										label='Arabic name'
										inputProps={{
											inputMode:
												'text',
										}}
										type='string'
										placeholder=''
										error={
											!!errors.nameAr
										}
									/>
								)}
							/>
							<FormHelperText
								error={!!errors.nameAr}>
								{errors.nameAr?.message}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Name English */}
						<FormControl fullWidth>
							<Controller
								name='nameEn'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'English name is required',
									},
								}}
								render={({ field }) => (
									<TextField
										size='small'
										{...field}
										label='English name'
										inputProps={{
											inputMode:
												'text',
										}}
										type='string'
										placeholder=''
										error={
											!!errors.nameEn
										}
									/>
								)}
							/>
							<FormHelperText
								error={!!errors.nameEn}>
								{errors.nameEn?.message}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Description Arabic */}
						<FormControl fullWidth>
							<Controller
								name='descriptionAr'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'Arabic description is required',
									},
								}}
								render={({ field }) => (
									<TextField
										size='small'
										{...field}
										label='Arabic description'
										inputProps={{
											inputMode:
												'text',
										}}
										multiline
										maxRows={4}
										type='string'
										placeholder=''
										error={
											!!errors.descriptionAr
										}
									/>
								)}
							/>
							<FormHelperText
								error={
									!!errors.descriptionAr
								}>
								{
									errors.descriptionAr
										?.message
								}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Description English */}
						<FormControl fullWidth>
							<Controller
								name='descriptionEn'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'English description is required',
									},
								}}
								render={({ field }) => (
									<TextField
										size='small'
										{...field}
										label='English description'
										inputProps={{
											inputMode:
												'text',
										}}
										multiline
										maxRows={4}
										type='string'
										placeholder=''
										error={
											!!errors.descriptionEn
										}
									/>
								)}
							/>
							<FormHelperText
								error={
									!!errors.descriptionEn
								}>
								{
									errors.descriptionEn
										?.message
								}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={12}>
						{/* Return Policy Arabic */}
						<FormControl fullWidth>
							<Controller
								name='returnPolicyAr'
								control={control}
								render={({ field }) => (
									<TextField
										size='small'
										{...field}
										label='Arabic Return Policy'
										inputProps={{
											inputMode:
												'text',
										}}
										multiline
										maxRows={4}
										type='string'
										placeholder=''
										error={
											!!errors.returnPolicyAr
										}
									/>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={12}>
						{/* Return Policy English */}
						<FormControl fullWidth>
							<Controller
								name='returnPolicyEn'
								control={control}
								render={({ field }) => (
									<TextField
										size='small'
										{...field}
										label='English Return Policy'
										inputProps={{
											inputMode:
												'text',
										}}
										multiline
										maxRows={4}
										type='string'
										placeholder=''
										error={
											!!errors.returnPolicyEn
										}
									/>
								)}
							/>
						</FormControl>
					</Grid>

					{/* Category */}
					<Grid item xs={12}>
						<FormControl fullWidth>
							<InputLabel id='store-category-select-label'>
								Category
							</InputLabel>
							<Controller
								name='category'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'Category is required',
									},
								}}
								render={({ field }) => (
									<>
										<Select
											labelId='category-select-label'
											id='category-select'
											label='Category'
											error={
												!!errors.category
											}
											{...field}>
											{categoryOptions?.map(
												(
													category
												) => (
													<MenuItem
														key={
															category.value
														}
														value={
															category.value
														}>
														{
															category.label
														}
													</MenuItem>
												)
											)}
										</Select>

										<FormHelperText
											error={
												!!errors.category
											}>
											{
												errors
													.category
													?.message
											}
										</FormHelperText>
									</>
								)}
							/>
						</FormControl>
					</Grid>

					{/* Logo */}
					<Grid item xs={12}>
						<InputLabel variant='standard'>
							Logo
						</InputLabel>
						<FormControl fullWidth>
							<FileUploader
								accept='image/*'
								handleChange={
									handleImageChange
								}
								types={['JPEG', 'PNG']}>
								<div className='file-uploader-body'>
									<button className='btn'>
										Add Files
									</button>
									{watchImage instanceof
									File ? (
										<p>
											File name:{' '}
											{
												watchImage?.name
											}
										</p>
									) : (
										<img
											src={watchImage}
											alt='logo'
										/>
									)}
									<p>
										Accepts .png and
										.jpg
									</p>
								</div>
							</FileUploader>
						</FormControl>
					</Grid>

					{/* Working Hours */}
					<Grid item xs={12}>
						{/* start and end days work hours */}
						<LocalizationProvider
							dateAdapter={AdapterDayjs}>
							{workingHoursFields.fields.map(
								(field, index) => (
									<Grid
										container
										columnSpacing={3}
										marginBottom={3}
										key={field.id}>
										<Grid item xs={12} marginBottom={1}>
											<InputLabel
												className='capitalize'
												variant='standard'>
												{mapDayIndexToDayName(
													field.day
												)}
											</InputLabel>
										</Grid>
										<Grid item xs={6}>
											<FormControl
												fullWidth>
												<TimePickerInput
													name={`workingHours[${index}].operationTimeStart`}
													label='Time Start'
													control={
														control
													}
													rules={{}}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6}>
											<FormControl
												fullWidth>
												<TimePickerInput
													name={`workingHours[${index}].operationTimeEnd`}
													label='Time End'
													control={
														control
													}
													rules={{}}
												/>
											</FormControl>
										</Grid>
									</Grid>
								)
							)}
						</LocalizationProvider>
					</Grid>

					{/* Submit Buttons */}
					<Grid item xs={12}>
						<Stack
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							spacing={3}>
							<Button
								type='button'
								size='large'
								variant='outlined'
								onClick={closeModal}
								color='error'>
								Cancel
							</Button>

							<Button
								type='submit'
								size='large'
								variant='contained'
								color='basePrimary'>
								Create Store
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
};
