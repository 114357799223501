import { Fragment, useEffect, useMemo, useState } from "react";
import { FilterHeader, FloatingForm, NavBar, Table } from "../../components";

//Icons
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { useListDiscount } from "../../@core/hooks";
import { Discount as DiscountModel } from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { AddDiscountForm } from "./add-discount-form.component";
import { EditDiscountForm } from "./edit-discount-form.component";

function Discount() {
  const { t } = useTranslation();
  const [showEditDiscountForm, setShowEditDiscountForm] = useState(false);
  const [showAddDiscountForm, setShowAddDiscountForm] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedDiscount, setSelectedDiscount] = useState<DiscountModel>();
  const { merchant } = useCurrentMerchantSelector();

  const {
    data: discounts,
    isLoading,
    refetch: RefetchDiscountList,
  } = useListDiscount({
    storeId: merchant!!._id,
    limit: 10,
    page,
  });

  useEffect(() => {
    RefetchDiscountList();
  }, [page]);

  const columns = useMemo(
    () => [
      {
        title: t("discount.type"),
        selector: (row: DiscountModel) =>
          row.type.toLowerCase().charAt(0).toUpperCase() +
          row.type.split("_").join(" ").toLowerCase().slice(1),
      },
      {
        title: t("discount.amount"),
        selector: (row: DiscountModel) => `${row.discount} %`,
      },
      {
        title: t("discount.status"),
        selector: (row: DiscountModel) => (
          <span
            className={`${row.isActive ? "approvedStatus" : "cancelledStatus"}`}
          >
            {row.isActive ? "Active" : "Inactive"}
          </span>
        ),
      },
      {
        title: `${t("discount.startOn")} (${t("discount.dateTime")})`,
        selector: (row: DiscountModel) =>
          new Date(row.startDate).toLocaleString(),
      },
      {
        title: `${t("discount.endOn")} (${t("discount.dateTime")})`,
        selector: (row: DiscountModel) =>
          new Date(row.endDate).toLocaleString(),
      },
      {
        title: t("discount.action"),
        selector: (row: DiscountModel) => (
          <div className="flexActions">
            <FiEdit
              size={20}
              onClick={() => {
                setSelectedDiscount(row);
                setShowEditDiscountForm(true);
              }}
            />
          </div>
        ),
      },
    ],
    [t]
  );

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("Active");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.target.name === "search" && setSearch(e.target.value);
    e.target.name === "status" && setStatus(e.target.value);
  };

  if (isLoading) {
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.discounts")} />
        <FilterHeader
          hasAdd={true}
          onAdd={() => setFormVisible(true)}
          selectorData={() => [
            { _id: 1, name: "Active" },
            { _id: 2, name: "Inactive" },
          ]}
        />
        <div className="tableContainer" id="tableContainer">
          <Table columns={columns} data={[]}>
            <LoadingSpinner />
          </Table>
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <NavBar title={t("menuNavigation.discounts")} />

      <FilterHeader
        // Search
        searchName="search"
        searchPlaceHolder="Search by Type"
        searchValue={search}
        searchOnChange={handleChange}
        // Select Box
        selectorPlaceHolder="Active Status"
        selectorName="status"
        selectorValue={status}
        selectorOnChange={handleChange}
        selectorData={() => [
          { _id: 1, name: "Active" },
          { _id: 2, name: "Inactive" },
        ]}
        // Add Button
        hasAdd={true}
        onAdd={() => setShowAddDiscountForm(true)}
      />

      <div className="tableContainer" id="tableContainer">
        <Table
          columns={columns}
          data={discounts?.data as any[]}
          handlePageChange={(selectedItem) => setPage(selectedItem.selected)}
          pageCount={discounts!!.numberOfPages}
          initialPage={page}
        />
      </div>

      {/* Add Discount Form */}
      <FloatingForm
        visible={showAddDiscountForm}
        setVisible={() => setShowAddDiscountForm(false)}
        title="Add Discount"
        body={
          <AddDiscountForm closeModal={() => setShowAddDiscountForm(false)} />
        }
        btnName="Add Discount"
      />

      {/* Edit Discount Form */}
      <FloatingForm
        visible={showEditDiscountForm}
        setVisible={() => setShowEditDiscountForm(false)}
        title="Edit Discount"
        body={
          <EditDiscountForm
            selectedDiscount={selectedDiscount}
            closeModal={() => {
              setSelectedDiscount(undefined);
              setShowEditDiscountForm(false);
            }}
          />
        }
        btnName="Edit Discount"
      />
    </div>
  );
}

export default Discount;
