import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputBox } from "../../components";

//Styles
import "./style.scss";

//Icons
import { BiArrowBack } from "react-icons/bi";

type ForgetPasswordFormObj = {
  email: string;
};

function ForgetPassword() {
  const [formObj, setFormObj] = useState<ForgetPasswordFormObj>({
    email: "",
  });
  return (
    <div className="forgetPasswordContainer">
      <div className="container">
        <div className="logo">
          <img src="/logoDark.png" alt="logo" />
          <span>BASE</span>
        </div>
        <div>
          <h3>Forgot Password</h3>
          <h5>We will send a link on your email to reset your password</h5>
        </div>
        <div className="formContainer">
          <form>
            <InputBox
              type="text"
              placeholder="Email"
              value={formObj.email}
              onChange={(email: string) => setFormObj({ ...formObj, email })}
            />
            <button>Submit</button>
          </form>
          <div className="navigation">
            <Link to="/login">
              <BiArrowBack size={20} />
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
