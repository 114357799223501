import { StoreDiscountTypesEnum } from '../../@core/models/discount/discount-types.enum';

export const StoreDiscountTypes = [
	{
		value: StoreDiscountTypesEnum.STORE_DISCOUNT,
		label: 'Store Discount',
	},
	{
		value: StoreDiscountTypesEnum.CATEGORY_DISCOUNT,
		label: 'Category Discount',
	},
	{
		value: StoreDiscountTypesEnum.SUB_CATEGORY_DISCOUNT,
		label: 'Sub Category Discount',
	},
	{
		value: StoreDiscountTypesEnum.PRODUCT_DISCOUNT,
		label: 'Product Discount',
	},
];
