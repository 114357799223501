import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRegister } from "../../@core/hooks";
import { loginSuccess } from "../../@core/slice";

//styles
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { SignupSellerFormData } from "../../@core/models";
import { theme } from "../../assets/styles/mui-theme";
import CustomePhoneNumber from "./components/PhoneNumber";
import "./style.scss";

function SignUp() {
  const { mutate: register } = useRegister();
  const navigte = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (values: SignupSellerFormData) => {
    register(
      {
        email: values.email,
        password: values.password,
        name: values.name,
        countryCode: values.countryCode,
        phoneNumber: values.phoneNumber.substring(4),
      },
      {
        onSuccess: (data) => {
          dispatch(loginSuccess(data));
          toast.success(`Welcome ${data.user?.name ?? ""}`);
          navigte("/");
        },
        onError: () => {
          toast.error("Failed to register");
        },
      }
    );
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupSellerFormData>({
    defaultValues: {
      name: "",
      email: "",
      countryCode: "+965",
      phoneNumber: "",
      password: "",
    },
  });

  return (
    <div className="signUpContainer">
      <div className="container">
        <div className="logo">
          <img src="/logoDark.png" alt="logo" />
          <span>BASE</span>
        </div>
        <div>
          <h3>Account Registration</h3>
          <h5>Please complete the required fields below</h5>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        label="Name"
                        inputProps={{
                          inputMode: "text",
                        }}
                        type="string"
                        placeholder=""
                        error={!!errors.name}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.name}>
                    {errors.name?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        label="Email"
                        inputProps={{
                          inputMode: "text",
                        }}
                        type="string"
                        placeholder=""
                        error={!!errors.email}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.email}>
                    {errors.email?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Phone number is required",
                      },
                      validate: (value) => isValidPhoneNumber(value),
                    }}
                    render={({ field: { value, onChange } }) => (
                      <PhoneInput
                        inputComponent={CustomePhoneNumber}
                        defaultCountry="KW"
                        value={value}
                        onChange={onChange}
                        id="phoneNumber"
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.phoneNumber}>
                    {errors.phoneNumber?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                      pattern: {
                        // regex to validate password with at least one lowercase letter, one uppercase letter, one digit, one special character, and is at least 8 characters long
                        value:
                          /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})$/,
                        message:
                          "Password must be at least one lowercase letter, one uppercase letter, one digit, one special character, and is at least 8 characters long",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        label="Password"
                        inputProps={{
                          inputMode: "text",
                        }}
                        type="password"
                        placeholder=""
                        error={!!errors.password}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.password}>
                    {errors.password?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <br />
          <Grid item xs={12} maxWidth={"35%"} style={{ marginLeft: 300 }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="basePrimary"
                fullWidth
              >
                Sign up
              </Button>
              <div>
                Already have an account?<Link to="/login"> Sign in</Link>
              </div>
            </Stack>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
