import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.scss";
import { useLogin } from "../../@core/hooks";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../@core/slice";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { theme } from "../../assets/styles/mui-theme";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

type LoginDto = {
  email: string;
  password: string;
};

function Login() {
  const { mutate: login } = useLogin();
  const navigte = useNavigate();
  const dispatch = useDispatch();
  const environment = process.env.NODE_ENV;

  const onSubmit = (values: LoginDto) => {
    login(
      { email: values.email, password: values.password },
      {
        onSuccess: (data) => {
          dispatch(loginSuccess(data));
          toast.success(`Welcome ${data.user?.name ?? ""}`);
          navigte("/");
        },
        onError: () => {
          toast.error("Email or password could be wrong");
        },
      }
    );
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDto>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <div className="loginContainer">
      <div className="container">
        <div className="logo">
          <img src="/logoDark.png" alt="logo" />
          <span>BASE</span>
        </div>

        {environment === "development" && (
          <div>
            <h3>Development Mode</h3>
            email: <span>ms.slama@icloud.com</span>
            <br />
            password: <span>Moha@123</span>
          </div>
        )}

        <div
          className="loginTitle"
          style={{
            textAlign: "center",
          }}
        >
          <h3 style={{ fontWeight: "700" }}>Login</h3>
          <h5 style={{ fontWeight: "400" }}>
            Enter your email and password to access account.
          </h5>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid
              container
              spacing={2}
              style={{ justifyContent: "center", width: "fit-content" }}
            >
              <Grid item xs={12} md={8}>
                <FormControl fullWidth>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Email"
                        type="string"
                        placeholder=""
                        error={!!errors.email}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.email}>
                    {errors.email?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Password"
                        type="password"
                        placeholder=""
                        error={!!errors.password}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.password}>
                    {errors.password?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                >
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="basePrimary"
                    style={{ width: "50%" }}
                  >
                    Sign In
                  </Button>
                  <div>
                    Don't have an account?<Link to="/signup"> Sign Up</Link>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default Login;
