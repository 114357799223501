import React, { useEffect } from 'react';
import { SelectBox, InputBox } from '../../components';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
	Box,
	Grid,
	FormHelperText,
	TextField,
	Stack,
	Button,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { useForm, Controller } from 'react-hook-form';
import { useListCategory } from '../../@core/hooks/query/category';
import { DatePickerInput } from '../../components/date-picker/date-picker-input.component';
import { TimePickerInput } from '../../components/time-picker/time-picker.component';
import { addTimeToDate } from '../../utils/dayjs.util';
import { PercentageNumber } from '../../utils/regex-patterns.util';
import { DiscountFormData } from '../../@core/models';
import { StoreDiscountTypes } from './discount-types';
import { useStore } from '../../@core/hooks';
import dayjs from 'dayjs';
import { Discount as DiscountModel } from '../../@core/models';
import { useGetDiscount } from '../../@core/hooks/query/discount/get-discount.hook';
import { useEditDiscount } from '../../@core/hooks/mutation/discount/edit-discount.hook';

interface Props extends React.HTMLProps<HTMLDivElement> {
	closeModal: () => void;
	selectedDiscount?: DiscountModel;
}

export const EditDiscountForm: React.FC<Props> = ({
	closeModal,
	selectedDiscount,
}) => {
	const { data: store } = useStore();
	const { data: discount } = useGetDiscount({
		storeId: store?.data._id!,
		discountId: selectedDiscount?._id!,
	});
	const { data: categories } = useListCategory();
	const { mutate: editDiscount } = useEditDiscount();

	const categoryOptions = categories?.data?.map(
		(category) => ({
			value: category._id,
			label: category.name,
		})
	);

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm<DiscountFormData>({
		defaultValues: {
			discount: '',
			image: '',
			type: '',
			store: '',
			product: '',
			startDate: null,
			endDate: null,
			startTime: null,
			endTime: null,
		},
	});

	const watchImage = watch('image');

	const onSubmit = (data: DiscountFormData) => {
		const startDate = addTimeToDate(
			data.startDate,
			data.startTime
		).toISOString();

		const endDate = addTimeToDate(
			data.startDate,
			data.startTime
		).toISOString();

		// remove startTime and endTime from data
		delete data.startTime;
		delete data.endTime;

		const discountData = {
			...data,
			startDate,
			endDate,
		};

		editDiscount({
			storeId: store?.data._id!,
			discountId: discount?._id!,
			body: discountData,
		}, {
			onSuccess: () => {
				closeModal();
			},
			onError: (error) => {
				console.log('Edit Discount Error: ', error);
			}
		});

		console.log('Form Data: ', {
			...data,
			startDate,
			endDate,
		});
	};

	const handleImageChange = (file: File) => {
		setValue('image', file);
	};

	useEffect(() => {
		if (discount) {
			console.log('Discount: ', discount);
			reset({
				type: discount.type,
				discount: discount.discount,
				startDate: dayjs(discount.endDate),
				startTime: dayjs(discount.endDate),
				endDate: dayjs(discount.endDate),
				endTime: dayjs(discount.endDate),
				image: discount.image,
				// product: discount.product._id,
			});
		}
	}, [discount, reset]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box>
				<Grid
					container
					rowSpacing={3}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={12} md={6}>
						{/* Discount Type */}
						<FormControl fullWidth>
							<InputLabel id='discount-type-select-label'>
								Discount Type
							</InputLabel>
							<Controller
								name='type'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'Discount Type is required',
									},
								}}
								render={({ field }) => (
									<Select
										id='discount-type-select'
										label='Discount Type'
										labelId='discount-type-select-label'
										error={
											!!errors.type
										}
										{...field}>
										{StoreDiscountTypes?.map(
											(type) => (
												<MenuItem
													key={
														type.value
													}
													value={
														type.value
													}>
													{
														type.label
													}
												</MenuItem>
											)
										)}
									</Select>
								)}
							/>
							<FormHelperText
								error={!!errors.type}>
								{errors.type?.message}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Discount Amount */}
						<FormControl fullWidth>
							<Controller
								name='discount'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'Discount Amount is required',
									},
									pattern: {
										value: PercentageNumber,
										message:
											'Amount must be a number between 0 and 100',
									},
								}}
								render={({ field }) => (
									<TextField
										{...field}
										label='Discount Amount'
										inputProps={{
											inputMode:
												'numeric',
										}}
										type='number'
										placeholder='10%'
										error={
											!!errors.discount
										}
									/>
								)}
							/>
							<FormHelperText
								error={!!errors.discount}>
								{errors.discount?.message}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						{/* Product Category */}
						<FormControl fullWidth>
							<InputLabel id='product-category-select-label'>
								Product / Category
							</InputLabel>

							<Controller
								name='product'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'Product is required',
									},
								}}
								render={({ field }) => (
									<>
										<Select
											labelId='product-category-select-label'
											id='product-category-select'
											label='Product / Category'
											error={
												!!errors.product
											}
											{...field}>
											{categoryOptions?.map(
												(
													category
												) => (
													<MenuItem
														key={
															category.value
														}
														value={
															category.value
														}>
														{
															category.label
														}
													</MenuItem>
												)
											)}
										</Select>

										<FormHelperText
											error={
												!!errors.product
											}>
											{
												errors
													.product
													?.message
											}
										</FormHelperText>
									</>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						{/* Sub Category */}
						<FormControl fullWidth>
							<InputLabel id='product-category-select-label'>
								Sub Category
							</InputLabel>
							<Controller
								name='subCategory'
								control={control}
								rules={{}}
								render={({ field }) => (
									<>
										<Select
											labelId='sub-category-select-label'
											id='sub-category-select'
											label='Sub Category'
											error={
												!!errors.product
											}
											{...field}>
											{categoryOptions?.map(
												(
													category
												) => (
													<MenuItem
														key={
															category.value
														}
														value={
															category.value
														}>
														{
															category.label
														}
													</MenuItem>
												)
											)}
										</Select>

										<FormHelperText
											error={
												!!errors.product
											}>
											{
												errors
													.product
													?.message
											}
										</FormHelperText>
									</>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* start date */}
						<FormControl fullWidth>
							<DatePickerInput
								name='startDate'
								label='Start Date'
								rules={{
									required: {
										value: true,
										message:
											'Start Date is required',
									},
								}}
								control={control}
								error={errors.startDate}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* start time */}
						<FormControl fullWidth>
							<TimePickerInput
								name='startTime'
								label='Start Time'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'Start Time is required',
									},
								}}
								error={errors.startTime}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* end date */}
						<FormControl fullWidth>
							<DatePickerInput
								name='endDate'
								label='End Date'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'End Date is required',
									},
								}}
								error={errors.endDate}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* end date time */}
						<FormControl fullWidth>
							<TimePickerInput
								name='endTime'
								label='End Time'
								control={control}
								rules={{
									required: {
										value: true,
										message:
											'End Time is required',
									},
								}}
								error={errors.endTime}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<InputLabel variant='standard'>
							Add Discount Image
						</InputLabel>
						<FormControl fullWidth>
							<FileUploader
								accept='image/*'
								handleChange={
									handleImageChange
								}
								types={[
									'JPEG',
									'PNG',
									'GIF',
								]}>
								<div className='file-uploader-body'>
									<button className='btn'>
										Add Files
									</button>
									{watchImage instanceof
									File ? (
										<p>
											File name:{' '}
											{
												watchImage?.name
											}
										</p>
									) : (
										<img
											src={watchImage}
											height='60'
											alt='discount'
										/>
									)}

									{watchImage ? null : (
										<p>
											Accepts .gif,
											.png and .jpg
										</p>
									)}
								</div>
							</FileUploader>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<Stack
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							spacing={3}>
							<Button
								type='button'
								size='large'
								variant='outlined'>
								Cancel
							</Button>

							<Button
								type='submit'
								size='large'
								variant='contained'>
								Add Discount
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
};
